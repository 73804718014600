const CONTENT_API_URL = `https://www.midiversities.co.za/admin/wp-json/wp/v2`;

export const CONTENT_ROUTES = {
  SERVICES: function (params) {
    return `${CONTENT_API_URL}/service/${params}`;
  },
  TEAM: function (params) {
    return `${CONTENT_API_URL}/team-member/${params}`;
  },
  BANNERS: function (params) {
    return `${CONTENT_API_URL}/banner/${params}`;
  },
  PROJECTS: function (params) {
    return `${CONTENT_API_URL}/project/${params}`;
  },
  MEDIA_BY_PAGE: function (id) {
    return `${CONTENT_API_URL}/media/?parent=${id}`;
  },
  MEDIA_BY_ID: function (id) {
    return `${CONTENT_API_URL}/media/${id}`;
  },
  POST_PAGE: function (type, slug) {
    return `${CONTENT_API_URL}${type}/?slug=${slug}`;
  },
  
};
